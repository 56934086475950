import { ReactElement } from 'react';
import { ApiBaseProps } from '../api';
import { LoadingContact } from '../loadingContact';
import { ShipmentDetailsRow } from '../shipments/shipments';

export type DatatablePriceRequest = {
  id: number;
  number: string;
  amount: string | ReactElement;
  pickup: string;
  pickupDate: string;
  deliveryDate: string;
  validUntilDateStr: string;
  validUntilDate: string;
  destination: string;
  status: PriceRequestStatus;
  statusText: string;
  price: string;
  hasComment: boolean;
  hasFile: boolean;
  newOperatorComments: number;
  type: number;
};

export type PriceRequestsFilters = {
  [key: string]: string | string[] | number | undefined;
  number?: string;
  reference?: number | string;
  pickupCountries?: string[];
  pickUpDateFrom?: string;
  pickUpDateTo?: string;
  deliveryCountries?: string[];
  arrivalDateFrom?: string;
  arrivalDateTo?: string;
  statuses?: string[];
  consignee?: string;
  consignor?: string;
  withoutShipment?: string;
};

export type PriceRequestsApiRequest = {
  page?: number;
  sizePerPage?: number;
  createdByMe?: boolean;
  sortName?: string;
  sortOrder?: string;
  filters?: PriceRequestsFilters;
} & ApiBaseProps;

export type PriceRequestApiRequest = {
  id: number;
} & ApiBaseProps;

export type PriceRequestsPayload = {
  contextObjects?: PriceRequest[];
  pages: number;
  totalCount: number;
};

export type PriceRequest = {
  id: number;
  number: string;
  company: string;
  pickup: string;
  destination: string;
  loadingCompany: string;
  loadingAddressCountry: string;
  loadingAddressZip: string;
  shortLoadingAddress: string;
  deliveryCompany: string;
  deliveryAddressCountry: string;
  deliveryAddressZip: string;
  shortDeliveryAddress: string;
  pickupDate: string;
  deliveryDate: string;
  arrivalDate: string;
  validUntilDate: string;
  bestPrice: number;
  quantity: number;
  ldm: number;
  status: string;
  offeredPricesCount: number | null;
  chosenPrice: number | null;
  hasComment: boolean;
  hasFile: boolean;
  newOperatorComments: number;
  type: number;
};

export type PriceRequestOfferedPrice = {
  characterization: PriceOfferCharacterization | null;
  comment: string | null;
  fee: number | null;
  id: number;
  partner: number | null;
  partnerName: string;
  price: number | null;
  purchasePrice: number | null;
  transitTime: string;
  earliestArrivalDate: string | null;
  earliestPickupDate: string | null;
  latestArrivalDate: string | null;
  latestPickupDate: string | null;
  onTimeDelivery: number | null;
  comments: PriceComment[];
};

export type PriceComment = {
  language: string;
  comment: string;
};

export type PriceRequestPayload = {
  id: number;
};

export type PriceRequestShipments = {
  id: number;
  number: string;
};

export type PriceRequestDetailsPayload = {
  id?: number;
  number?: string;
  companyId: number;
  destinationAddress: string;
  shipmentDetailsRows: ShipmentDetailsRow[];
  earliestArrivalTime: string | null;
  earliestArrivalDate: string | null;
  confirmedEarliestArrivalDate: string | null;
  latestArrivalTime: string | null;
  latestArrivalDate: string | null;
  confirmedLatestArrivalDate: string | null;
  earliestPickupTime: string | null;
  earliestPickupDate: string | null;
  confirmedEarliestPickupDate: string | null;
  latestPickupTime: string | null;
  latestPickupDate: string | null;
  confirmedLatestPickupDate: string | null;
  validUntilDate: string;
  isFixedPickupDate: boolean;
  isFixedArrivalDate: boolean;
  acceptPriceForDays: false;
  noticeEmails?: string;
  offeredPrices: PriceRequestOfferedPrice[];
  originAddress: string;
  otherInstructions: string;
  receiver: LoadingContact;
  regularShipments: boolean;
  regularShipmentsPeriod: RegularShipmentPeriod | null;
  regularShipmentsNumber: string | null;
  rejectReason: number;
  selectedPriceOffer: string | null;
  sender: LoadingContact;
  status: PriceRequestStatus;
  statusString: 'Draft' | 'WaitingConfirmed';
  tailLiftTruck: false;
  tailLiftTruckDelivery: false;
  type: number;
  useReceiverAlternative: boolean;
  useSenderAlternative: boolean;
  senderAlternative?: LoadingContact | null;
  receiverAlternative?: LoadingContact | null;
  shipments: PriceRequestShipments[];
  autoSavedObject?: string;
  createdBy?: string;
  createdOn?: string;
  sentBy?: string;
  sentOn?: string;
};
export type UnSavedPriceRequestApiRequest = {
  payload: PriceRequestDetailsPayload;
  id: string;
} & ApiBaseProps;

export type SavePriceRequestApiRequest = {
  id?: string | null;
  payload: PriceRequestDetailsPayload;
  draft?: boolean;
} & ApiBaseProps;

export type RejectOfferApiRequest = {
  requestId: number;
  selectedOffer: number;
  rejectReason: number;
  notes?: string | null;
};

export type ApproveOfferApiRequest = {
  requestId: number;
  selectedOffer: number;
};

export enum PriceRequestStatus {
  NotSelected,
  Draft,
  Canceled = 5,
  Received = 10,
  WaitingConfirmed = 20,
  Accepted = 25,
  Rejected = 30,
  Expired = 40,
}

export enum RegularShipmentPeriod {
  NotSelected,
  Day,
  Week = 5,
  Month = 10,
  Year = 15,
}

export enum PriceOfferCharacterization {
  FixedDatesYouPreferred = 1,
  LogtechHoldingRecommends = 2,
  BestPriceWithFlexibleDates = 3,
  Basic = 4,
  Premium = 5,
  AskFromTheOperator = 6,
  Express = 7,
  YourPriceList = 8,
}

export enum PriceOfferRejectReason {
  NotSelected = 0,
  TooHighPrice = 1,
  Postpone = 2,
  DeliveryNotNeededAnymore = 3,
  SomethingElse = 4,
}

export const regularShipmentPeriodLabels: Record<RegularShipmentPeriod, string> = {
  [RegularShipmentPeriod.NotSelected]: '',
  [RegularShipmentPeriod.Day]: 'DAY',
  [RegularShipmentPeriod.Week]: 'WEEK',
  [RegularShipmentPeriod.Month]: 'MONTH',
  [RegularShipmentPeriod.Year]: 'YEAR',
};

export const priceOfferRejectReasonLabels: Record<PriceOfferRejectReason, string> = {
  [PriceOfferRejectReason.NotSelected]: '',
  [PriceOfferRejectReason.TooHighPrice]: 'PRICE_REQUEST.PRICE_TOO_HIGH',
  [PriceOfferRejectReason.Postpone]: 'PRICE_REQUEST.SHIPMENT_POSTPONED',
  [PriceOfferRejectReason.DeliveryNotNeededAnymore]: 'PRICE_REQUEST.DELIVERY_NOT_NEEDED',
  [PriceOfferRejectReason.SomethingElse]: 'PRICE_REQUEST.ASKED_AS_REFERENCE_PRICE',
};
