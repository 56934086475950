export default {
    WELCOME_TEXT: "Hej, {0}!",
    OUT_OF_OFFICE: "{0} inte på kontoret.",
    DASHBOARD_TEXT: "Vi uppdaterar vår portal varje vecka och vi ska meddela dig när något nytt och spännande kommer!",
    PRIMARY_CONTACT: "Din kontaktperson:",
    READ_MORE: "Läs mer",
    LESS: 'Visa mindre',
    CLOSE: 'Stäng',
    OPEN: "Öppen",
    COPY: "Kopiera",
    SELECTED: "vald",
    CO2_EMISSIONS:"utsläpp",
    CO2_EMISSIONS_TOOLTIP: "CO2-fotavtrycket beräknas efter att försändelsen har levererats, med hänsyn till ruttspecifika egenskaper.",
    COST_BREAKDOWN: 'Kostnadsuppdelning',
    Transport: 'Transport',
    HomeDelivery: 'Hemleverans',
    Customs: 'Tull',
    Storage: 'Lager',
    ForwardedTransportService: 'Vidarebefordrad transporttjänst',
    LogisticsManagement: 'Logistikhantering',
    Insurance: 'Försäkring',
    CREATED_ON: 'skapad',
    REFRESH_DATA: 'Uppdatera vyn',
    TRY_FULL_SCREEN: 'Prova fullskärmsvyn för att få en ännu bättre översikt',
    DATEPICKER_PLACEHOLDER: "dd.mm.åååå",
    NOT_FOUND_HEADING: "Tyvärr!",
    NOT_FOUND_DESC: "Sidan du söker finns inte eller ",
    NOT_FOUND_DESC_2: "du saknar rättigheter för att se den.",
    NOT_FOUND_ERROR_CODE: "Felkod: 404",
    FILTER_RESULT: "Filtrera resultat",
    FILTER_SEARCH_RESULT: "Sökresultat ",
    ERROR_OUR_BUG: "Det är inte ditt fel utan vårt! Vi håller på med att lösa problemet och det blir åtgärdat snart!",
    GO_TO_HOMEPAGE: "Gå till sidan för transporter",
    GO_BACK: 'Gå tillbaka',
    SEARCH_INSERT_KEYWORD: 'Ange sökord',
    FORGOT_PASSWORD: 'Har du glömt ditt lösenord?',
    SEND_EMAIL: 'Skicka epost',
    OPERATOR_SEEN: 'Operatören har sett',
    RESET_PASSWORD_BOX_TITLE: "Återställ lösenord",
    RESET_PASSWORD_BTN: "Förändra",
    FORGOT_PASSWORD_BOX_TITLE: "Har du glömt inloggningsuppgifter?",
    FORGOT_PASSWORD_BOX_SUCCESS: "Tack! Om vi kände igen dig bör du snart få en länk för att återställa ditt lösenord via e-post.",
    IMPORT_ADDRESSES: 'Importera adresser från en Excel-fil',
    LOGIN_BOX_TITLE: "Kundportalen",
    LOGIN_BOX_DESC: 'Logga in i vår kundportal för att se dina transporter och deras statusar.',
    INVITATION_BOX_TITLE : 'Skapa en ny användare',
    LOGIN_FAILED: "Automatisk inloggning misslyckades.",
    TERMS_OF_SERVICE: 'Användarvillkor',
    USERNAME: 'Användarnamn',
    PASSWORD: 'Lösenord',
    PASSWORD_AGAIN: 'Lösenord igen',
    SIGN_IN: 'Logga in',
    USER_NOT_CREATED: 'Användaren skapades inte!',
    CREATE_USER: 'Skapa användare',
    PASSWORDS_DO_NOT_MATCH: 'Lösenorden matchar inte',
    CREATE_USER_PASSWORD_ERROR: "Kontrollera lösenordets krav. (Krävs minst en specialtecken, siffra, liten / stor bokstav.)",
    PASSWORD_HINT: 'Lösenordets minsta längd är 8 tecken. Krävs minst en specialtecken, siffra, liten / stor bokstav.',
    FAILED_TO_LOG_IN: 'Inloggningen misslyckades',
    FAILED_TO_LOG_IN_BLOCKED: 'Ditt konto är blockerat. Kontakta customer@laasone.com för att låsa upp ditt konto',
    MY_PROFILE: 'Min profil',
    THIS_IS_DEMO_ENVIRONMENT: 'Det här är en demomiljö. De data som anges här kommer inte att hanteras.',
    INVOICES: 'Fakturor',
    CLAIM_INVOICE:'Försäljningsfaktura',
    WAREHOUSE: 'Lagervy',
    ALL: 'Alla',
    INCOMING: 'Inkommande',
    OUTGOING: 'Utgående',
    YESTERDAY: 'Igår ({0})',
    TODAY: 'Idag ({0})',
    TOMORROW: 'Imorgon ({0})',
    ENTER_FULLSCREEN: 'Gå till fullskärm',
    LABEL_NOT_PRINTED: 'Ej tryckta etiketter',
    LABEL_PRINTED: 'Tryckta etiketter',
    CMR_NOT_PRINTED: 'Ej tryckta CMR-fraktsedlar',
    CMR_PRINTED: 'Tryckta CMR-fraktsedlar',
    INVOICES_EXTRA: 'fakturor',
    ADDRESS_BOOK: 'Adressboken',
    RESULTS_FROM_ADDRESS_BOOK: 'Resultat ur adressboken: ',
    PRICE_REQUESTS: 'Prisförfrågningar',
    RESULTS_FROM_GOOGLE_MAPS: 'Resultat ur Google Maps: ',
    COMPANY_PROFILE: 'Företagets profil',
    COMPANY_DETAILS: 'Företagets uppgifter',
    LOG_OUT: 'Logga ut',
    SHOW_ONLY_SHIPMENTS_CREATED_BY_ME: 'Visa endast transporter skapade av mig',
    SHOW_ONLY_SHIPMENTS_CREATED_BY_ME_TOOLTIP: 'Om denna ruta är markerad ser du bara transporter skapade av dig i listan. Om du avmarkerar rutan visas alla transporter från ditt företag här',
    SHOW_ONLY_PRICE_REQUESTS_CREATED_BY_ME: 'Visa endast prisförfrågningar skapade av mig',
    SHOW_ONLY_PRICE_REQUESTS_CREATED_BY_ME_TOOLTIP: 'Om denna ruta är markerad ser du endast prisförfrågningar skapade av dig i listan. Om du avmarkerar rutan visas alla prisförfrågningar från ditt företag här',
    OPEN_FILTER: 'Öppna filter',
    SORT_BY: 'Sortera efter',
    SELECT: 'Välj',
    SHIPMENT_NUMBER: 'Transportnummer',
    REFERENCE_NO: 'Referensnummer',
    AMOUNT: 'Mängd',
    CARRIER: 'Transportör',
    PICKUP: 'Hämtning',
    PICKUP_DATE: 'Hämtningsdatum',
    PICKUP_ADDRESS: 'Hämtningsadress',
    DELIVERY: 'Leverans',
    DELIVERY_DATE: 'Leveransdatum',
    DELIVERY_ADDRESS: 'Leveransadress',
    PICKUP_CONTACT: 'Upphämtningkontakt',
    DELIVERY_CONTACT: 'Leveranskontakt',
    STATUS: 'Status',
    DATE: 'Datum',
    SHOW: 'Visa',
    UPLOADED: 'uppladdat',
    CLOSE_ALERT: 'Stäng meddelandet',
    LABELS_SENT_DETAILS: 'Etiketter har skickats',
    LOCK_FILE_TOOLTIP: 'Lås filen så att andra inte kan ladda ner eller ta bort den',
    UNLOCK_FILE_TOOLTIP: 'Lås upp filen så att andra också kan ladda ner eller ta bort den',
    LOCKING_FILE_CONFIRM_TEXT: 'Är du säker på att du vill ändra filens låsning?',
    FILE_IS_LOCKED: 'Låst ',
    SHIPMENT_SORT_SHIPMENT_NO_DSC: 'Leveransnummer: fallande',
    SHIPMENT_SORT_SHIPMENT_NO_ASC: 'Leveransnummer: stigande',
    SHIPMENT_SORT_PICKUP_DATE_DSC: 'Hämtningsdatum: fallande',
    SHIPMENT_SORT_PICKUP_DATE_ASC: 'Hämtningsdatum: stigande',
    SHIPMENT_SORT_DELIVERY_DATE_DSC: 'Leveransdatum: fallande',
    SHIPMENT_SORT_DELIVERY_DATE_ASC: 'Leveransdatum: stigande',
    TEMPLATE_SORT_TEMPLATE_NAME_ASC: 'Mallnamn: stigande',
    TEMPLATE_SORT_TEMPLATE_NAME_DESC: 'Mallnamn: fallande',
    TEMPLATE_SORT_TEMPLATE_PICKUP_ASC: 'Hämtande företag: stigande',
    TEMPLATE_SORT_TEMPLATE_PICKUP_DESC: 'Hämtande företag: fallande',
    TEMPLATE_SORT_TEMPLATE_DELIVERY_ASC: 'Levererande företag: stigande',
    TEMPLATE_SORT_TEMPLATE_DELIVERY_DESC: 'Levererande företag: fallande',
    ADDRESSBOOK_SORT_NAME_ASC: 'Adressnamn: stigande',
    ADDRESSBOOK_SORT_NAME_DESC: 'Adressnamn : fallande',
    ADDRESSBOOK_SORT_COMPANY_ASC: 'Företagsnamn: stigande',
    ADDRESSBOOK_SORT_COMPANY_DESC: 'Företagsnamn: fallande',
    ADDRESSBOOK_SORT_COUNTRY_ASC: 'Land: stigande',
    ADDRESSBOOK_SORT_COUNTRY_DESC: 'Land: fallande',
    PRICE_REQUEST_SORT_SHIPMENT_NO_DSC: 'Prisförfrågningens nummer: fallande',
    PRICE_REQUEST_SORT_SHIPMENT_NO_ASC: 'Prisförfrågningens nummer: stigande',
    SHIPMENT_FILTER_SHIPMENT_NO: 'Transportnummer',
    SHIPMENT_FILTER_REFERENCE_NO: 'Referensnummer',
    SHIPMENT_FILTER_PICKUP_FROM: 'Hämtning från',
    SHIPMENT_FILTER_PICKUP_DATE: 'Hämtningsdatum',
    SHIPMENT_FILTER_DELIVERY_TO: 'Leverans till',
    SHIPMENT_FILTER_DELIVERY_DATE: 'Leveransdatum',
    SHIPMENT_FILTER_STATUS: 'Status',
    SEARCH: 'Sök',
    SELECT_COUNTRY: 'Välj land',
    DOWNLOAD_POD: 'Ladda ned mottagningsbevis {0}',
    COPY_TRACKING_LINK: 'Kopiera spårningslänken',
    TRACKING_LINK_COPIED: 'Spårningslänken har kopierats till klippbordet',
    SEEN_BY_THE_OPERATOR: 'Sedd av operatören',
    ON: 'PÅ',
    OFF: 'AV',
    PICKUP_AND_DELIVERY_DATES: 'Hämtnings- och leveransdatum',
    EARLIEST_PICKUP: 'Hämtning tidigast',
    LATEST_PICKUP: 'Hämtning senast',
    EARLIEST_DELIVERY: 'Leverans tidigast',
    LATEST_DELIVERY: 'Leverans senast',
    FIXED_PICKUP: 'Fast hämtning',
    FIXED_DELIVERY: 'Fast leverans',
    ITEMS_IN_THE_SHIPMENT: 'Artiklar i transporten',
    ITEMS_ADDED_PRICE_REQUEST: 'Artiklar i prisförfrågningen',
    COMMENTS_REGARDING_THE_ITEMS: 'Kommentarer om artiklar',
    PICKUP_FROM: 'Hämtning från',
    DELIVERY_TO: 'Leverans till',
    LENGTH: 'Längd',
    HEIGHT_TOOLTIP: 'Längden är fast vid den valda artikeltypen',
    WIDTH: 'Bredd',
    WIDTH_TOOLTIP: 'Bredden är fast vid den valda artikeltypen',
    HEIGHT: 'Höjd',
    VOLUME: 'Volym',
    WEIGHT_PC: 'Vikt/st',
    TOTAL_WEIGHT: 'Totalvikt',
    LDM: 'Lastmeter',
    PRODUCT_DESCRIPTION: 'Produktbeskrivning',
    DESCRIPTION: 'Beskrivning',
    LOCATION: 'Plats',
    UN_CODE: 'FN-kod',
    PG: 'PG',
    CLASS: 'Klass',
    COLD_FROZEN: 'Kall/fryst',
    STACKABLE: 'Stapelbar',
    DELICATE: 'Ömtåligt',
    PROPER_SHIPPING_NAME: 'Officiell transportbenämning',
    YOU_ARE_LOGGED_OUT: 'Du är utloggad',
    SIDEBAR_DASHBOARD: 'Informationspanelen',
    SIDEBAR_SHIPMENTS: 'Transporter',
    SIDEBAR_PRICE_REQUESTS: 'Prisförfrågningar',
    SIDEBAR_USERS: 'Användare',
    ADD_DOCUMENTS_TITLE: 'Lägg till dokument',
    DASHBOARD: {
        LAST_MESSAGES: 'Senaste meddelanden',
        PRICE_REQUEST_SECTION: 'Prisförfrågningar som kräver din uppmärksamhet',
        SHIPMENTS_SECTION: 'Försändelser som kräver din uppmärksamhet',
        NOT_CONFIRMED_PR: 'Ej bekräftad ({0})',
        NOT_ORDERED_PR: 'Ej beställd ({0})',
        CURRENT_MONTH_STATISTICS: 'Statistik över levererade transporter för den aktuella månaden',
        INCOMING: 'INKOMMANDE',
        OUTGOING: 'UTGÅENDE',
        TOTAL_WEIGHT: 'TOTALVIKT',
        VOLUME: 'VOLYM',
        IN_COUNTRY_PERCENT: '{0}% av utgående transporter avsåg rutten {1}-{1}',
        SENDING_COST: 'Dina transportkostnader var {0} €',
    },
    USERS:{
        ADD_USER:'Bjud in ny användare',
        EDIT_USER_TITLE:'Redigera användare',
        FIRST_NAME:'Förnamn',
        LAST_NAME:'Efternamn',
        LIMITED_ACCESS:'Ange anpassad åtkomst för användaren',
        LIMITED_ACCESS_TOOLTIP:'Om inga användarrättigheter har angetts, kan användaren som standard se all data i portalen.',
        USERNAME: 'Användarnamn / e-post',
        PRIMARY_CONTACT: 'LaasOne primär kontakt',
        BTN_SAVE_BUTTON: "Spara",
        RIGHTS:{
            NO_ACCESS: 'Ingen åtkomst',
            VIEW: 'Endast visning',
            ADD_EDIT: 'Lägg till eller redigera'
        },
        INVITED: 'Väntande',
        DELETE_SUCCESS: 'Användare borttagen',
        DELETE_CONFIRMATION: 'Är du säker på att du vill ta bort användaren?',
        BTN_DELETE: "Ta bort",
        TEXT_USER_INVITED: "Användarinvitation skickad",
        TEXT_USER_SAVED: "Användardata sparad",
        LIMITED_ACCESS_LABEL: "Begränsad åtkomst",
        SHOW_SHIPMENT_PRICE: "Visa fraktkostnad",
        INVITATION_URL_COPIED: "Inbjudningslänk kopierad! Dela denna länk med den nya användaren så att de kan slutföra registreringen.",
        ACCESS_ALL_OFFICES: 'Tillgång till försändelser från alla platser',
        ACCESS_ALL_OFFICES_TOOLTIP: 'Som standard har användaren åtkomst till all platsinformation. För att anpassa detta, vänligen välj specifika platser.',
        CHOOSE_OFFICES: 'Välj platser att ge tillgång till',
        WAREHOUSE_WORKER: 'Användaren har endast åtkomst till lagervyn.',
        WAREHOUSE_WORKER_TOOLTIP: 'Begränsar användarens åtkomst till att endast visa lagerrelaterad information.',
    },
    USERNAME_EXISTS: "Det här användarnamnet finns redan",
    REPLAY: 'Svara',
    DROPZONE: {
        DRAG_AND_DROP: 'Dra och släpp en fil här eller',
        CHOOSE_FROM_COMPUTER: 'välj filen från din dator',
        SUPPORTED_FILES: 'Filtyper som stöds: PDF, PNG, JPG, JPEG',
        SUPPORTED_FILES_XLS: 'Filtyper som stöds: XLSX',
        SUPPORTED_FILES_PDF: 'Filtyper som stöds: PDF',
        ERROR: 'Fil “{0}” är av en otillåten filtyp',
        SUPPORTED_MAX_SIZE: "Max 10 MB"
    },
    INVOICE: {
        SEARCH_BY_ALL_INVOICES_TEXT_FIELDS: 'Sök faktura enligt nummer',
        DATE: 'Fakturadatum',
        NUMBER: 'Fakturanummer',
        PRICE: 'Pris',
        DOWNLOAD: 'Ladda ned (PDF)'
    },
    INVOICE_SORT_DATE_ASC: 'Datum: stigande',
    INVOICE_SORT_DATE_DESC: 'Datum: fallande',
    INVOICE_SORT_TOTAL_ASC: 'Totalt: stigande',
    INVOICE_SORT_TOTAL_DESC: 'Totalt: fallande',
    CANCEL: 'Avbryt',
    APPROVE: 'Godkänn',
    YES: 'Ja',
    NO: 'Nej',
    SAVE_CHANGES: 'Spara ändringarna',
    SAVE_CHANGES_ADDRESS_BOOK: 'Spara ändringarna',
    BREADCRUMB: {
        SHIPMENTS: 'Transporter',
        SHIPMENTS_ADD: 'Lägg till ny transport',
        EDIT_SHIPMENT_DRAFT_NO: 'Redigera transportutkast nr {0}',
        PRICE_REQUESTS: 'Prisförfrågningar',
        PRICE_REQUESTS_ADD: 'Lägg till ny prisförfrågan',
        EDIT_PRICE_REQUEST_DRAFT_NO: 'Redigera utkast till prisförfrågan nr {0}',
        PRICE_REQUEST: 'Prisförfrågan',
        COMPANY_PROFILE: 'Företagets profil',
        TEMPLATES: 'Mallar',
        TEMPLATES_ADD: 'Lägg till ny mall',
        EDIT_TEMPLATE: 'Redigera mallen',
        CLAIM: 'Reklamation',
        CLAIM_ADD: 'Lägg till reklamation',
        EDIT_CLAIM_DRAFT_NO: 'Redigera reklamationsutkastet'
    },
    DAY: 'dag',
    WEEK: 'vecka',
    MONTH: 'månad',
    YEAR: 'år',
    SHIPMENTS_SCENARIO: {
        FILTER: {
            FILTER: 'Filtrera',
            CLOSE_FILTER: 'Stäng filtret',
            CLEAR_ALL_FIELDS: 'Töm alla fält',
            SHIPMENT_NR: 'Transportnummer',
            REFERENCE_NR: 'Referensnummer',
            PICK_UP_FROM: 'Hämtningsland',
            SELECT_COUNTRY: 'Välj land',
            PICK_UP_DATE: 'Hämtningsdatum',
            DELIVERY_TO: 'Leveransland',
            DELIVERY_DATE: 'Leveransdatum',
            STATUS: 'Status',
            SELECT_STATUS: 'Välj status',
            OOOPS: 'Tyvärr, inga resultat : (',
            WE_SEARCHED: 'Vi sökte riktigt noga och kunde inte hitta några transporter som matchar dina sökkriterier.',
            TRY_DIFFERENT: 'Prova med andra filterkriterier eller ta bort några filter.',
            INVALID_DATE: 'Fel datum',
            PICKUP_COMPANY: 'Hämtande företag',
            DELIVERY_COMPANY: 'Levererande företag',
            HELLO_USERNAME: 'Hej, {0}!',
            NO_SHIPMENTS_HERE: 'Inga transporter? Det går inte an!',
            MAKE_A_FEW_SELECTIONS: 'Gör några val, fyll i ett par fält och din transport kommer iväg!',
            NEED_HELP: 'Behöver du hjälp? Kontakta oss: ',
            PSST_DONT_WANT_TO_ADD: 'Shh! Vill du inte lägga till något än? Avmarkera för att se transporter för ditt företag!',
            HERE_YOU_WILL_SEE: 'Här ser du tillgängliga prisförfrågningar som du har lagt till.',
            START_ADDING_A_NEW_SHIPMENT: 'Börja lägga till en ny transport eller prisförfrågan genom att klicka på den gula knappen här uppe!',
            EXPORT_XLS: 'Exportera filtrerade resultat (.xlsx)'
        },
    },
    RELEASE_FROM_TOP: 'Släpp uppifrån',
    DOWNLOAD_LABELS: 'Ladda ned etiketter',
    DOWNLOAD_CMR: 'Ladda ned CMR-fraktsedlar',
    COPY_SHIPMENT: 'Kopiera',
    CANCEL_SHIPMENT: 'Annullera transporten',
    COPY_PRICE_REQUEST: 'Kopiera prisförfrågningen',
    CANCEL_PRICE_REQUEST: 'Annullera prisförfrågningen',
    SHIPMENT_DETAILS: 'Transportens uppgifter',
    BASED_ON: 'Utifrån',
    COST: 'Kostnad',
    COST_TOOLTIP: 'Priset visas exklusive moms',
    WHERE_IS_MY_SHIPMENT: 'Var är min transport någonstans?',
    DETAILED_SHIPMENT_EVENTS: 'Detaljerade händelser för transporten',
    DOCUMENTS: 'Dokument',
    ADD_NEW_DOCUMENT: 'Lägg till nytt dokument',
    NOT_SELECTED: 'Ej valt',
    DRAFT: 'Utkast',
    CANCELED: 'Annullerat',
    MISSING_DATA: 'Data saknas',
    SHIPMENT_CREATED: 'Skapat',
    WAITING_CONFIRMED: 'I avvaktan på godkännande',
    CONFIRMED: 'Hantering pågår',
    PROCESSING: 'Skickad till transportören',
    IN_TRANSIT: 'På väg',
    DELIVERING: 'Leverans pågår',
    DELIVERED: 'Levererad',
    LAST_DELIVERED: 'Senast levererad',
    WAITING_READINESS_REQUEST: 'I avvaktan på förfrågning om slutförande',
    WAITING_FOR_CONFIRMATION: 'Väntar på bekräftelse',
    ACCEPTED: 'Godkänd',
    REJECTED: 'Avslagen',
    EXPIRED: 'Utgången',
    PENDING_PRICES: 'I avvaktan på priser',
    NOT_KNOWN: 'okänd',
    ADD_NEW_SHIPMENT: 'Lägg till ny transport',
    ADD_NEW_PRICE_REQUEST: 'Lägg till ny prisförfrågan',
    ADD_NEW_CLAIM: 'Lägg till ny reklamation',
    ADD_NEW_SHIPMENT_FROM_TEMPLATE: 'Lägg till ny transport ur en mall',
    ADD_NEW_SHIPMENT_FROM_EXCEL: 'Lägg till nya transporter ur en Excel-mall',
    EDIT_SHIPMENT_DRAFT_NO: 'Redigera transportutkast nr {0}',
    ADD_NEW_TEMPLATE: 'Lägg till ny mall',
    EDIT_TEMPLATE_DRAFT_NAME: 'Redigera mallen',
    ADD_NEW: 'Lägg till ny',
    SWAP: 'Byt ut',
    ADDRESSBOOK: {
        FILTER: {
            INPUT_TEXT: 'Sök i alla adressfält',
            BUTTON: 'Sök'
        },
        ADDRESSES: 'adresser',
        ONE_ADDRESS: 'adress',
        IMPORT_MODAL_TITLE: 'Importera adresser från en Excel-fil',
        IMPORT_MODAL_TITLE_CORRECT_ROWS: '{0} adresser har importerats från Excel-filen',
        IMPORT_MODAL_TITLE_INCORRECT_ROWS: '{0} adresser ska importeras från Excel-filen',
        IMPORT_MODAL_TITLE_SMALL: 'Bara en Excel-fil kan importeras åt gången',
        TOOLTIPS: {
            ADDRESS_NOTE_TOOLTIP: 'verktygstips',
            ADDRESS_REFERENCE_TOOLTIP: 'verktygstips',
        },
        EDIT_ADDRESS: 'Redigera adressen',
        ADDRESS_NAME: 'Adressnamn',
        COMPANY_NAME: 'Företagets namn',
        ADDRESS: 'Adress',
        COUNTRY: 'Land',
        CITY: 'Ort',
        CONTACT_PERSON: 'Kontaktperson',
        CONTACT_NUMBER: 'Kontaktnummer',
        CONTACT_EMAIL: 'E-post',
        DELETE_BUTTON: 'Radera adressen',
        DELETE_CONFIRM_TEXT: 'Är du säker på att du vill radera adress "{0}"?',
        BTN_IMPORT: 'Importera adresser',
        BTN_IMPORT_FINISH: 'Okej, uppfattat',
        BTN_IMPORT_CORRECT_ROWS: 'Importera rader med korrekt information',
        BTN_IMPORT_DOWNLOAD_ERROR_XLSX: 'Ladda ned filen med felrader (.xlsx)',
        ERROR_MALFORMED_ROWS_TEXT: 'Vissa rader nedan saknar uppgifter och kan därför inte importeras',
        ERROR_DUPLICATED_ROWS_TEXT: 'Vissa adresser nedan är redan sparade och kan därför inte importeras',
        MISSING: 'saknas',
        IMPORT_EXCEL_FILE: 'Importera en Excel-fil',
        DOWNLOAD_EXCEL_TEMPLATE: 'Ladda ned en Excel-mall',
        ADD_NEW_ADDRESS: 'Lägg till ny adress',
        SAVE_ADDRESS: 'Spara adressen',
        ADDRESS_ADDED_TO_ADDRESS_BOOK: 'Adressen har införts i adressboken',
    },
    PRICE_REQUEST: {
        TOOLTIP: {
            EXPIRES_SOON: 'Giltighetstiden går snart ut',
            CANCELED: 'Avslagen eller annullerad',
            CAN_NOT_BE_CANCELED: 'Denna prisförfrågan kan inte annulleras eftersom den redan hanteras av oss.'
        },
        PRICE_ORDER_NOW: 'Beställ nu',
        WE_CAN_OFFER_PRICES: 'Vi kan erbjuda priser',
        NO_PRICES: 'Fyll i uppgifter om artiklar och adresser för att se priserna.',
        NO_AUTOMATIC_PRICES: "Vi hittade tyvärr inga automatiska priser för din förfrågan. Operatören granskar den inlämnandet och meddelar dig vad vi kan erbjuda.",
        CREATE_SHIPMENT_ALERT_HEADING: 'Skapa ny order',
        CREATE_SHIPMENT_ALERT: 'För att lämna in en order öppnar systemet ett nytt transportformulär som är automatiskt ifyllt med uppgifter från den godkända prisförfrågningen.\n {0}Observera att priset endast gäller vid samma information som anges i prisförfrågningen.{1} När du beställer från en godkänd prisförfrågan kommer systemet inte att automatiskt beräkna ett nytt pris om uppgifter har ändrats, du behöver kontakta vår logistikspecialist för detta.',
        CANCELED: 'Prisförfrågningen har annullerats',
        REJECTED: 'Prisförfrågningen har avslagits',
        TITLE_ACCEPTED: 'Vad bra att du godkände priset på {0}€!',
        TITLE_ACCEPTED_VALID_UNTIL: 'Observera att priset gäller till och med {0}.',
        TITLE_ACCEPTED_TEXT: 'Du kan nu lägga till en ny transport med samma uppgifter som finns i prisförfrågningen. ',
        TITLE_EXPIRED: 'Denna prisförfrågan har gått ut!',
        HEADER_REJECTED_TITLE_0: 'Denna prisförfrågan är annullerad!',
        HEADER_REJECTED_TITLE_1: 'Vad tråkigt att du har avslagit vår offert : (',
        HEADER_REJECTED_TITLE_1_SMALL: 'Men det är okej, vi förstår.',
        HEADER_REJECTED_TITLE_1_1_SMALL: 'Om du vill fråga efter ett annat pris, börja helt enkelt \n med en ny prisförfrågan. Vi är glada över att kunna erbjuda dig det bästa möjliga priset!',
        PLEASE_START_A_NEW_PRICE_REQUEST: 'Börja med en ny prisförfrågan eller kopiera denna prisförfrågan för att få nya uppdaterade \n priser för din leverans.',
        HEADER_EXPIRED_OFFERS: 'Prisförfrågningen inkluderade följande priser, du har inte valt ut något pris åt dig: ',
        HEADER_EXPIRED_OFFER: 'Prisförfrågningen inkluderade ett pris som du har valt: ',
        OPERATOR_COMMENT: 'Operatörens kommentar: {0}',
        ADD_NEW_SHIPMENT: 'Skapa en order',
        ALREADY_GENERATED_SHIPMENTS: 'Du har redan bokat  transporter enligt denna prisförfrågan: ',
        INSURANCE: 'Försäkring',
        ITEMS_VALUE: 'Artikelns (artiklarnas) värde',
        CONDITION: 'Skick',
        PRICE: 'Pris',
        SAVE_AS_DRAFT_SUCCESS: 'Prisförfrågan har sparats som ett utkast',
        ADD_COMMENTS_REGARDING_THE_PRICE_REQUEST: 'Lägg till kommentarer om denna prisförfrågan',
        ADD_A_COMMENT: 'Lägg till kommentar',
        ADD_AN_E_MAIL_WHERE_WE_WILL_SEND_THE_OFFER: 'Lägg till en e-postadress dit vi ska skicka offerten',
        IM_LOOKING_FOR_PRICES_FOR_30_DAYS: "Jag kommer att frysa det valda priset i 30 dagar",
        IM_LOOKING_FOR_PRICES_FOR_REGULAR_SHIPMENTS: "Jag vill få priser på regelbundna transporter",
        IM_LOOKING_FOR_PRICES_FOR_REGULAR_SHIPMENTS_TOOLTIP: "Om du skickar sådana transporter regelbundet så kan vi sannolikt erbjuda dig ett bättre pris. Om detta är fallet, vänligen fyll i formuläret nedan så att vi kan ta detta i beaktande.",
        PLEASE_ADD_THE_NUMBER_OF_TIMES_AND_PERIOD: 'Lägg till beställnings frekvensen och perioden',
        SEND_PRICE_REQUEST: 'Skicka prisförfrågningen',
        ADD_ITEMS_TO_YOUR_PRICE_REQUEST: 'Lägg till artiklar i din prisförfrågan',
        OFFERED_PRICES: '{0} priser',
        OFFERED_PRICE: '{0} pris',
        FILTER: {
            NUMBER: 'Prisförfrågningens nummer'
        },
        SIDEBAR: {
            DETAILS: 'Prisförfrågningens uppgifter',
            INSURANCE: 'Försäkring',
            ITEMS_VALUE: 'Artikelns (artiklarnas) värde',
            CONDITION: 'Skick',
            DESCRIPTION: 'Beskrivning',
            REGULAR_SHIPMENTS: 'Regelbundna transporter',
            REGULARITY: 'Regelbundenhet',
            STATUSES: 'Prisförfrågningens status',
            ACTIONS: 'Kopiera och annullera prisförfrågningen',
            TIMES_PER: 'gånger per',
        },
        NUMBER: 'Nej',
        PICK_UP_DATE: 'Hämtningsdatum',
        DELIVERY_DATE: 'Leveransdatum',
        VALID_UNTIL_DATE: 'Gäller till och med',
        HEADER_TITLE_1: 'Vi arbetar flitigt med din prisförfrågan!',
        HEADER_TITLE_1_SMALL: 'Vi meddelar dig här och via e-post när vi har tagit fram priserna för din förfrågan.',
        EMAIL_NOTIFICATIONS: 'E-postadress för meddelande om offert',
        COMMENTS_TITLE: 'Kommentarer till prisförfrågningen',
        CLICK_ONE_OR_MORE_ITEMS_ABOVE_TO_START_ADDING_THEM_TO_YOUR_PRICE_REQUEST: 'Klicka på en artikel eller flera artiklar ovan för att börja lägga till dem i din prisförfrågan',
        TIMES: 'gånger',
        PER: 'per',
        SELECT_PERIOD: 'Välj period',
        EMAIL_WHERE_WE_WILL_SEND_THE_OFFER: 'E-postadress dit vi ska skicka offerten',
        I_WANT_TO_INSURE_MY_ITEMS: 'Jag vill försäkra mina artiklar',
        TOTAL_VALUE_OF_ITEMS: 'Totalvärdet på artiklarna',
        PLEASE_ADD_THE_DESCRIPTION_OF_YOUR_ITEMS: 'Lägg till en beskrivning på dina artiklar',
        TIMES_AND_PERIOD: 'Antalet tillfällen och perioden',
        CONFIRM_PRICE_REQUEST: 'Bekräfta prisförfrågningen',
        CANCEL_PRICE_REQUEST_BTN: 'Ja, annullera prisförfrågningen',
        CANCEL_PRICE_REQUEST: 'Annullera prisförfrågningen',
        CANCEL_CONFIRMATION: 'Är du säker på att du vill annullera prisförfrågan "PR-{0}"?',
        CANCEL_SUCCESS: 'Prisförfrågningen har annullerats',
        DAYS_DELIVERY: 'dagars leverans',
        SAME_DAY_DELIVERY: 'Leverans samma dag',
        WORKING_DAY_DELIVERY: 'arbetsdag',
        WORKING_DAYS_DELIVERY: 'arbetsdagar',
        ESTIMATED_DELIVERY: 'Beräknad leverans',
        ON_TIME_DELIVERY: 'Leverans på angiven tid',
        THIS_OPTION_IS_CHOSEN: 'Detta alternativ har valts',
        CHOOSE_THIS_OPTION: 'Välj detta alternativ',
        THIS_OPTION_UNSELECT: 'Avmarkera det här alternativet',
        ADD_AN_EMAIL: 'Ange här en e-postadress eller flera e-postadresses åtskilda med kommatecken. Vi kommer att skicka offerten till dessa e-postadresser när den är klar.',
        PRICE_REQUEST_VALID_UNTIL: 'Prisförfrågningen gäller till och med',
        APPROVE_OFFER: 'Godkänn offerten',
        APPROVE_OFFER_ORDER_NOW: 'Bekräfta och beställ nu',
        APPROVE_SUCCESS: 'Offerten har godkänts',
        REJECT_THIS_OFFER: 'Avslå offerten',
        REJECT_SUCCESS: 'Offerten har avslagits',
        PRICE_TOO_HIGH: 'Priset är för högt',
        SHIPMENT_POSTPONED: 'Transporten har blivit uppskjuten',
        DELIVERY_NOT_NEEDED: 'Leveransen behövs inte',
        ASKED_AS_REFERENCE_PRICE: 'Jag frågade eftersom jag ville ha ett jämförelsepris',
        OTHER: 'Annat',
        OFFER_REJECTION_DIALOG_TITLE: 'Vänligen meddela oss varför du har avslagit offerten?',
        YOUR_REASON_FOR_REJECTION: 'Din orsak till avslag',
        SEND_YOUR_ANSWER: 'Skicka ditt svar',
        PLEASE_COMMENT_YOUR_CHOICE: 'Vänligen kommentera ditt val',
        REASON_FOR_REJECTION_HAS_TO_BE_SPECIFIED: 'Vänligen fyll i orsaken till avslag',
        OFFER_APPROVAL_DIALOG_TITLE: 'Är du säker på att du vill godkänna priset på {0}€?',
        OFFER_APPROVAL_DIALOG_TEXT: 'Du kan beställa en transport på basis av offerten när du har bekräftat priset.',
        PICK_ONE_PRICE_ERROR: 'Välj ett pris som du vill godkänna för denna prisförfrågan',
        COMMENT_RELATED_TO_PRICE: 'Kommentar om priset'
    },
    TEMPLATE: {
        TEMPLATE_NAME: 'Mallnamn',
        TEMPLATES: 'Mallar',
        TEMPLATE: 'Mall',
        TEMPLATES_ADD: 'Lägg till ny mall',
        SEARCH_BY_ALL_TEMPLATES_TEXT_FIELDS: 'Sök i uppgifter om mallnamn eller adress',
        DELETE_BUTTON: 'Radera',
        DELETE_CONFIRM_TEXT: 'Är du säker på att du vill radera mall "{0}"?',
        DELETE_SUCCESS: 'Mallen har raderats',
        DELETE_ERROR: 'Ett problem uppstod vid radering av mallen',
        ADD_ITEMS_TO_YOUR_TEMPLATE: 'Redigera artiklar i mallen',
        SAVE_TEMPLATE: 'Spara ändringarna',
        ADD_TEMPLATE_NAME: 'Lägg till mallnamn',
        CHANGE_TEMPLATE_NAME: 'Redigera mallnamnet',
        TEMPLATE_NAME_LABEL: 'Mallnamn',
        TEMPLATE_NAME_TOOLTIP: 'Mallnamnet visas när du skapar en ny transport enligt mallen. Ange ett namn som du lätt kommer ihåg så att du enkelt kan välja rätt mall.',
        SAVE_SUCCESS: 'Mallen har sparats',
        ADDRESSES: {
            EDIT_ROUTE_ADDRESSES: 'Redigera malladresser'
        },
        TEMPLATE_CREATED: 'Mallen skapades {0}',
        SEARCH_FOR_A_TEMPLATE_TITLE: 'Sök en mall: ',
    },
    SHIPMENT: {
        STAR_TOOLTIP:'Följ upp sändelsen',
        PICK_ONE_PRICE_ERROR: 'Välj ett pris för godkännande',
        SEND: 'Skicka',
        SEND_MESSAGE: 'Skicka meddelandet',
        HAS_FILES: 'Transporten har filer',
        COMMENTS: 'Meddelanden',
        HAS_COMMENT: 'Meddelanden finns',
        NEW_COMMENT: '1 nytt meddelande',
        NEW_COMMENTS: '{0} nya meddelanden',
        NO_NEW_COMMENTS: 'Inga nya meddelanden',
        ADD_A_COMMENT: 'Lägg till meddelande',
        CLEAR_THE_FIELD: 'Töm fältet',
        SHIPMENT_TYPE: 'Skicka/ta emot gods',
        TYPE: {
            SENDER: 'Jag skickar ut gods',
            RECEIVER: 'Jag tar emot gods',
        },
        YOU_HAVE_UNSAVED_CHANGES: 'Du har osparade ändringar.',
        DISCARD_SHIPMENT: 'Ta bort beställningen',
        TITLE_ADD_NEW_SHIPMENT_FROM_TITLE: 'Beställ ny transport enligt mallen',
        BTN_ADD_SHIPMENT: 'Beställ transport',
        FROM_TEMPLATE_DESCRIPTION: 'Öppna “Mallar” under användarmenyn i sidhuvudet för att skapa och hantera mallar.',
        BASED_ON_SHIPMENT: 'Baserat på transport {0}. ',
        SHIPMENT_CREATED: 'Transport skapad {0}',
        TRANSPORT_WITH_TAIL_LIFT_TRUCK: 'Transport med lastbil med bakgavellyft',
        TRANSPORT_WITH_TAIL_LIFT_TRUCK_DELIVERY: 'Delivery with tail lift truck',
        I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK_TOOLTIP: 'En lastbil med fastmonterad mekanisk anordning på bakgaveln. Den är avsedd för lastning av varor från marknivån eller från lastbryggans höjd till höjden på bilens lastgolv eller tvärtom.',
        SAVE_SHIPMENT_AS_A_TEMPLATE: 'Spara transport {0} som en mall',
        THIS_SHIPMENT_IS_NOT_SAVED_AS_TEMPLATE: 'Denna transport har inte sparats som en mall',
        THIS_SHIPMENT_IS_SAVED_AS_TEMPLATE: 'Denna transport är baserad på en mall',
        REMOVE_TEMPLATE_CONFIRM_TEXT: 'Är du säker på att du vill ta bort "{0}" från mallarna?',
        SAVE_THIS_SHIPMENT_AS_TEMPLATE_BUTTON: 'Spara denna transport som en mall',
        CANCEL_CONFIRMATION_DIALOG_TEXT: 'Är du säker på att du vill avbryta? Ändringarna i transportutkastet kommer inte att sparas.',
        REMOVE_TEMPLATE_BUTTON: 'Ta bort mallen',
        REMOVE_FROM_TEMPLATES: 'Hantera mallar',
        NAME_YOUR_TEMPLATE: 'Ge namn åt din mall',
        MANAGE_TEMPLATES: 'Hantera mallar',
        SAVE_TEMPLATE: 'Spara mallen',
        TEMPLATE_NAME: 'Mallnamn',
        NOTIFICATIONS_FOR_THIS_SHIPMENT: 'Meddelanden för denna transport',
        SEND_SHIPMENT: 'Skicka transporten',
        SAVE_AS_DRAFT: 'Spara som ett utkast',
        SAVE_AS_DRAFT_SUCCESS: 'Transporten har sparats som ett utkast',
        DELETE_DRAFT: 'Radera utkastet',
        DELETE_DRAFT_CONFIRMATION: 'Är du säker på att du vill radera transportutkast nr {0}?',
        DELETE_DRAFT_SUCCESS: 'Transportutkastet har raderats',
        SHIPMENTS: 'Transporter',
        SHIPMENT: 'Transport',
        SHIPMENT_NR: 'Transportnummer',
        REF_NR: 'Ref nr',
        REF_MISSING: 'Ref saknas',
        CARRIER_MISSING: 'Transportör saknas',
        PICKUP_MISSING: 'Hämtningsadress saknas',
        DELIVERY_MISSING: 'Leveransadress saknas',
        DATE_MISSING: 'Datum saknas',
        ADD_REFERENCE_NUMBER: 'Lägg till referensnummer',
        YOUR_SHIPMENTS_PRICE: "Priset på din transport",
        YOUR_SHIPMENTS_PRICE_DESC: 'De angivna priserna är endast vägledande och kan ändras om de godset inte överensstämmer med de angivna uppgifterna',
        NO_PRICES: 'Fyll i uppgifter om godset och adresser för att se priserna på din transport.',
        NO_AUTOMATIC_PRICES: "Tyvärr kan vi inte stödja automatiska priser för din transport. Operatören granskar din förfrågan och återkommer med en lösning.",
        AUTOMATIC_PRICES_IN_PROGRESS: 'Vi arbetar flitigt för att hitta de bästa priserna och lösningarna för just din förfrågan!',
        REFERENCE_NUMBER: 'Referensnummer',
        REFERENCE_NUMBER_TOOLTIP: 'Ett nummer som hänvisar till godset eller transporten i kundsystemet.',
        NOTIFY_ME_ABOUT_THIS_SHIPMENT: 'Meddela mig om denna transport',
        SAVE_THIS_SHIPMENT_AS_TEMPLATE: 'Spara denna transport som en mall',
        SAVE_THIS_SHIPMENT_AS_TEMPLATE_TOOLTIP: 'Transporten kan sparas som en mall för att kunna använda samma uppgifter i fortsättningen vid en ny transport.',
        EARLIEST_PICKUP_DATE: 'Hämtningsdatum tidigast',
        LATEST_PICKUP_DATE: 'Hämtningsdatum senast',
        EARLIEST_DELIVERY_DATE: 'Leveransdatum tidigast',
        LATEST_DELIVERY_DATE: 'Leveransdatum senast',
        FIXED_PICKUP_DATE: 'Fast hämtningsdatum',
        FIXED_DELIVERY_DATE: 'Fast leveransdatum',
        EARLIEST_PICKUP_TIME: 'Hämtningstid tidigast',
        LATEST_PICKUP_TIME: 'Hämtningstid senast',
        EARLIEST_DELIVERY_TIME: 'Leveranstid tidigast',
        LATEST_DELIVERY_TIME: 'Leveranstid senast',
        FIXED_PICKUP_TIME: 'Fast hämtningstid',
        FIXED_DELIVERY_TIME: 'Fast leveranstid',
        PLEASE_CHECK_YOUR_SHIPMENT_INFORMATION_BEFORE_CONFIRMING: 'Kontrollera uppgifterna för din transport innan du bekräftar',
        SIDEBAR: {
            ACTIONS: 'Kopiera transporten',
        },
        DATES: {
            ADD_PICKUP_DELIVERY_DATES: "Lägg till hämtnings- och leveransdatum",
            PICKUP_DATES: "Hämtningsdatum",
            DELIVERY_DATES: "Leveransdatum",
            PICK_PICKUP_DATES: "Lägg till hämtningsdatum tidigast och senast",
            PICK_DELIVERY_DATES: "Lägg till leveransdatum tidigast och senast",
            PICK_PICKUP_FIXED_DATE: "Lägg till fast hämtningsdatum",
            PICK_DELIVERY_FIXED_DATE: "Lägg till fast leveransdatum"
        },
        NAME_THIS_SHIPMENT_TEMPLATE: 'Ge namn åt denna transportmall',
        ADDRESSES: {
            ADD_ADDRESS: 'Lägg till adress',
            UPDATE_ADDRESS: 'Uppdatera adressen',
            ADD_ROUTE_ADDRESSES: 'Lägg till ruttadresser',
            REQUIRED_FIELDS_ARE_MARKED_WITH: 'Obligatoriska fält är markerade med',
            WHERE_TO_PICK_UP_FROM: 'Var ska godset hämtas?',
            WHERE_TO_DELIVER: 'Vart ska godset levereras?',
            RECENTLY_USED_ADDRESSES: 'Senast använda adresser',
            CHOOSE_FROM_ADDRESS_BOOK: 'Välj ur adressboken',
            ADD_MANUALLY: 'Lägg till manuellt',
            CLEAR_ALL_FIELDS: 'Töm alla fält',
            SAVE_THIS_ADDRESS: 'Spara adressen: ',
            NAME_THIS_ADDRESS: 'Ge namn åt denna adress',
            SENDER_AND_DELIVERY_ADDRESSES: 'Avsändarens adress och leveransadress',
            TYPE_HERE_THE_ADDRESS_AND_SELECT_IT_FROM_GOOGLE_RESULTS_OR_FROM_ADDRESS_BOOK: 'Ange adressen här och välj den ur Google-resultaten eller adressboken',
            COUNTRY: 'Land',
            CITY: 'Ort',
            POSTCODE: 'Postnummer',
            COMPANY_NAME: 'Företagets namn',
            CONTACT_PERSON: 'Kontaktperson',
            CONTACT_NUMBER: 'Kontaktnummer',
            EMAIL: 'E-post',
            REFERENCE: 'Referens',
            LOADING_REFERENCE: 'Lastningsreferens',
            LOADING_REFERENCE_TOOLTIP: 'Ett särskilt nummer för hämtnings-/lastningsplatsen/byggnaden osv.',
            UNLOADING_REFERENCE: 'Lossningsreferens',
            UNLOADING_REFERENCE_TOOLTIP: 'Ett särskilt nummer för leverans till en lossningsplats/byggnad osv.',
            ADD_AN_ALTERNATIVE_ADDRESS: 'Lägg till en alternativ adress',
            ADDRESS_DETAILS: 'Adressuppgifter',
            ADDRESS: 'Adress',
            ADD_MORE_INFORMATION: 'Ange närmare information',
            CLOSE_MORE_INFORMATION: 'Stäng närmare information',
            SENDER: {
                INFORMATION: 'Avsändarens uppgifter',
                ADDRESS: 'Avsändarens adress',
                CONTACT_PERSON: 'Avsändarens kontaktperson',
                ADDRESS_DETAILS: 'Avsändarens adressuppgifter',
                ADDRESS_DETAILS_TOOLTIP: 'Uppgifter om adressen för att ange ett specifikt ställe för hämtning av varorna (byggnadssida, dörr, terminal osv.)',
                ALTERNATIVE_ADDRESS: 'Avsändarens alternativa adress',
                ALTERNATIVE_CONTACT_PERSON: 'Avsändarens alternativa kontaktperson',
                ALTERNATIVE_ADDRESS_DETAILS: 'Avsändarens alternativa adressuppgifter',
                ADD_AN_ALTERNATIVE_ADDRESS: 'Lägg till en alternativ adress för avsändaren',
            },
            RECEIVER: {
                INFORMATION: 'Mottagarens uppgifter',
                ADDRESS: 'Mottagarens adress',
                CONTACT_PERSON: 'Mottagarens kontaktperson',
                ADDRESS_DETAILS: 'Mottagarens adressuppgifter',
                ADDRESS_DETAILS_TOOLTIP: 'Uppgifter om adressen avseende en specifik plats för leverans av varorna (byggnadssida, dörr, terminal osv.)',
                ALTERNATIVE_ADDRESS: 'Mottagarens alternativa adress',
                ALTERNATIVE_CONTACT_PERSON: 'Mottagarens alternativa kontaktperson',
                ALTERNATIVE_ADDRESS_DETAILS: 'Mottagarens alternativa adressuppgifter',
                ADD_AN_ALTERNATIVE_ADDRESS: 'Ange mottagarens alternativa adress',
            },
        },
        ADD_ITEMS_TO_YOUR_SHIPMENT: 'Lägg till gods till i din beställning',
        CLICK_ON_THE_ITEM_YOU_WANT_TO_ADD: 'Klicka på det godstyp du vill lägga till',
        ITEMS_ADDED: 'Godstyp har lagts till',
        CLICK_ONE_OR_MORE_ITEMS_ABOVE_TO_START_ADDING_THEM_TO_YOUR_SHIPMENT: 'Klicka på godstyp eller välj flera ovan för att börja tillägga dem i din beställning',
        I_NEED_PICKUP_WITH_A_TAIL_LIFT_TRUCK: 'Jag behöver hämtning med en lastbil med bakgavellyft',
        I_NEED_DELIVERY_WITH_A_TAIL_LIFT_TRUCK: 'Jag behöver leverans med en lastbil med bakgavellyft',
        ADD_A_COMMENT_REGARDING_THE_ITEMS_ADDED: 'Lägg till en kommentar gällande godset',
        DOCUMENTS: {
            DELETE_TOOLTIP: 'Detta dokument kan inte raderas eftersom det har skapats av  operatören',
        },
        CMR_NOT_AVAILABLE: 'Detta dokument kan inte laddas ned än eftersom ingen transportör har valts',
        POD_NOT_AVAILABLE: 'Detta dokument kan inte laddas ned än eftersom filer för mottagningsbevis saknas',
        ITEM: {
            SELECT_TYPE: 'Välj typ',
            TOTAL: 'Totalt',
            DETAILS: {
                PC: 'st',
                TYPE: 'Typ',
                LENGTH: 'Längd',
                LENGTH_TOOLTIP: 'Längden är fast vid den valda artikeltypen',
                WIDTH: 'Bredd',
                WIDTH_TOOLTIP: 'Bredden är fast vid den valda artikeltypen',
                HEIGHT: 'Höjd',
                VOLUME: 'Volym',
                WEIGHT_PC: 'Vikt/st',
                TOTAL_WEIGHT: 'Totalvikt',
                LDM: 'Lastmeter',
                LDM_TOOLTIP: 'Lastmeter (löpmeter av släpets längd vid full bredd och höjd). Detta beräknas automatiskt med utgångspunkt i längden, bredden och mängden men du kan ange ett annat värde vid behov.',
                REFERENCE_NUMBER: 'Referensnummer',
                PRODUCT_DESCRIPTION: 'Produktbeskrivning',
                UN_NO: 'FN-nummer',
                UN_NO_TOOLTIP: 'Ett nummer som identifierar farligt gods, farliga ämnen och artiklar',
                UN: 'FN',
                UN_CODE: 'FN-kod',
                PG: 'PG',
                PG_TOOLTIP: 'Farligt gods är indelat i 3 olika förpackningsgrupper enligt den fara som de medför',
                CLASS: 'Klass',
                CLASS_TOOLTIP: 'Klassificeringsnummer för farligt gods',
                DANGEROUS_GOODS: 'Farligt gods',
                COLD_FROZEN: 'Kall/fryst',
                STACKABLE: 'Stapelbar',
                DELICATE: 'Ömtåligt',
                MIN_TEMP: 'Min temp',
                MIN_TEMP_TOOLTIP: 'Den lägsta temperaturen på varorna som krävs för transport',
                MAX_TEMP: 'Max temp',
                MAX_TEMP_TOOLTIP: 'Den högsta temperaturen på varorna som krävs för transport',
                PROPER_SHIPPING_NAME: 'Officiell transportbenämning',
                PROPER_SHIPPING_NAME_TOOLTIP: 'Namn för att beskriva farliga egenskaper och sammansättningen av farligt gods',
                PROPER_SHIPPING_NAME_PLACEHOLDER: 'Svartkrut, pressat krut eller krut',
                PACKAGE_INFO: 'Ange måttet på den längsta sidan i fältet "Längd" om paketet är stapelbart.'
            },
            TYPE: {
                PALLET: 'Pall',
                PACKAGE: 'Paket',
                TRUCK: 'Lastbil',
                OTHER: 'Lastmeter',
                CONTAINER: 'Container',
                MACHINE:'Machine'
            },
            SUBTYPE: {
                EUR_PALLET: 'EUR-pall',
                FIN_PALLET: 'FIN-pall',
                PALLET: 'Pall',
                PACKAGE: 'Paket',
                BOX: 'Låda',
                FULL_TRUCK_LOAD: 'Full lastbilslast',
                LONG_PALLET: 'Lång pall',
                HALF_PALLET: 'Halvpall',
                LDM: 'Lastmeter',
                CONTAINER_20_DC: '20’DC',
                CONTAINER_40_DC: '40’DC',
                CONTAINER_40_HC: '40’HC',
                CONTAINER_OTHER: 'Annat',
                MACHINE:'Machine'
            },
            CLASS: {
                EXPLOSIVES: 'Sprängämnen',
                GASES: 'Gaser',
                FLAMMABLE_LIQUIDS: 'Lättantändliga vätskor',
                FLAMMABLE_SOLIDS: 'Lättantändliga fasta ämnen',
                OXIDIZERS: 'Oxiderande ämnen',
                TOXIC_SUBSTANCES: 'Giftiga ämnen',
                RADIOACTIVE: 'Radioaktiva ämnen',
                CORROSIVES: 'Frätande ämnen',
                MISCELLANOUS: 'Diverse',
            },
            PG: {
                PG1: 'Förpackningsgrupp I: hög fara',
                PG2: 'Förpackningsgrupp II: medelfara',
                PG3: 'Förpackningsgrupp III: låg fara',
            },
        },
        DELETE_DOCUMENT: 'Radera dokumentet',
        DELETE_CONFIRM_TEXT: 'Är du säker på att du vill radera dokument "{0}"?',
        ADD_DOCUMENT_MODAL_TITLE: 'Lägg till nytt dokument',
        ADD_DOCUMENT_BUTTON: 'Lägg till dokument',
        CUSTOMER_CONFIRMED: 'Bekräftad av kunden: {0}',
        PICKUP_ON: 'Hämtning: {0}',
        PICKUP_FROM: 'Hämtning från och med: {0}',
        PICKUP_BETWEEN: 'Hämtning mellan: {0} och {1}',
        DELIVERY_ON: 'Leverans: {0}',
        DELIVERY_FROM: 'Leverans: {0}',
        DELIVERY_BETWEEN: 'Leverans mellan: {0} och {1}',
        IMPORT_MODAL_TITLE: 'Importera transporter ur en Excel-fil',
        IMPORT_MODAL_TITLE_CORRECT_ROWS: '{0} transporter har importerats',
        IMPORT_MODAL_TITLE_INCORRECT_ROWS: 'Inga transporter har importerats ur Excel-filen',
        IMPORT_MODAL_TITLE_SMALL: 'Bara en Excel-fil kan importeras åt gången.',
        BTN_IMPORT: 'Importera transporter',
        BTN_IMPORT_FINISH: 'Okej, uppfattat.',
        BTN_IMPORT_CORRECT_ROWS: 'Okej, uppfattat. Jag ska ladda upp en ny fil',
        BTN_IMPORT_DOWNLOAD_ERROR_XLSX: 'Ladda ned filen med felrader (.xlsx)',
        ERROR_MALFORMED_ROWS_TEXT: 'Vissa rader nedan innehåller valideringsfel och kan därför inte importeras',
        ERROR_DUPLICATED_ROWS_TEXT: 'Vissa transporter som visas är redan sparade och kan därför inte importeras',
        EXCEL_ROW_NUMBER: 'Excel-radnummer',
        EXCEL_ROW_MISSING_WRONG_INFO: 'Uppgifter saknas eller fel uppgifter',
        EXCEL_ROW_EXISTS: 'Befintlig transport med samma uppgifter',
        EXCEL_IMPORT_ERRORS: {
            SENDER_REQUIRED: 'Avsändaren saknas',
            SENDER_ADDRESS_REQUIRED: 'Avsändarens adress saknas',
            SENDER_CITY_REQUIRED: 'Avsändarens ort saknas',
            SENDER_POST_CODE_REQUIRED: 'Avsändarens postnummer saknas',
            SENDER_COUNTRY_REQUIRED: 'Avsändarens land saknas',
            SENDER_CONTACT_PERSON_REQUIRED: 'Namnet på avsändarens kontaktperson saknas',
            SENDER_CONTACT_PHONE_REQUIRED: 'Telefonnumret till avsändarens kontaktperson saknas',
            RECEIVER_REQUIRED: 'Mottagaren saknas',
            RECEIVER_ADDRESS_REQUIRED: 'Mottagarens adress saknas',
            RECEIVER_CITY_REQUIRED: 'Mottagarens ort saknas',
            RECEIVER_POST_CODE_REQUIRED: 'Mottagarens postnummer saknas',
            RECEIVER_COUNTRY_REQUIRED: 'Mottagarens land saknas',
            RECEIVER_CONTACT_PERSON_REQUIRED: 'Namnet på mottagarens kontaktperson saknas',
            RECEIVER_CONTACT_PHONE_REQUIRED: 'Telefonnumret till mottagarens kontaktperson saknas',
            PICKUP_DATE_ERROR: 'Hämtningsdatumet saknas',
            DELIVERY_DATE_ERROR: 'Leveransdatumet saknas',
            DELIVERY_DATE_IS_EARLIER_THAN_PICKUP_ERROR: 'Leveransdatumet är tidigare än hämtningsdatumet',
            QUANTITY_ERROR: 'Mängden saknas',
            PACKAGE_ERROR: 'Pakettypen saknas',
            WEIGHT_ERROR: 'Vikten saknas',
            WIDTH_ERROR: 'Bredden saknas',
            LENGTH_ERROR: 'Längden saknas',
            HEIGHT_ERROR: 'Höjden saknas',
            PICKUP_DATE_IN_PAST: 'Hämtningsdatum har passerats',
            LDM_ERROR: 'Lastmeter saknas',
        },
        BTN_DOWNLOAD_XLSX_TEMPLATE: 'Ladda ned mallen (.xlsx)',
    },
    SWITCH: {
        YES: 'JA',
        NO: 'NEJ',
    },
    VALIDATION: {
        IS_REQUIRED_TEXT: 'Obligatoriska fält är markerade med *',
        INVALID_VALUE: 'Fel värde',
        THIS_FIELD_IS_REQUIRED: 'Fältet bör fyllas i',
        YOU_HAVE_TO_SELECT_AT_LEAST_ONE_ITEM: 'Du behöver välja minst en artikel',
        AT_LEAST_ONE_ITEM_NEEDS_TO_BE_ADDED_IN_THE_SHIPMENT: 'Välj minst 1 artikel för att slutföra transporten',
        SOME_REQUIRED_FIELDS_ARE_NOT_FILLED: 'Vissa obligatoriska fält är inte ifyllda',
        NEW_SHIPMENT_VALIDATION_ERROR: 'Transporten kan inte sparas, vissa uppgifter saknas i transporten.',
        INVALID_PHONE: 'Fel telefonnummer',
        INVALID_EMAIL: 'Fel e-postadress',
        SELECTED_DATE_IS_WRONG: 'Det valda datumet är fel',
        SELECTED_EARLIEST_DATE_IS_WRONG: 'Kontrollera det senaste datumet',
        SELECTED_ARRIVAL_DATE_IS_WRONG: 'Kontrollera det tidigaste leveransdatumet',
        TEMPLATE_NAME: 'Mallnamnet ska bestå av minst 3 tecken'
    },
    UNITS: {
        PC: 'st'
    },
    COMPANY: {
        OFFICES:"Kontors- och lageradresser",
        IS_PRIMARY_OFFICE: "Primärkontor",
        EDIT_OFFICE_ADDRESS: "Ange kontors- / lageradress och kontaktuppgifter",
        OFFICE_DELETE_CONFIRMATION:'Är du säker på att du vill ta bort adressen?',
        OFFICE_DELETE_SUCCESS:'Kontors- eller lageradressen har tagits bort!',
        CLIENT_CODE: "Kundkod",
        CLIENT_CODE_TOOLTIP: 'E.g You can share it to your suppliers to make orders.',
        ORDER_URL: 'URL',
        ORDER_URL_COPIED: 'Order url copied',
        COMPANY: 'Företag',
        COMPANY_NAME: 'Företagets namn',
        COMPANY_PROFILE: 'Företagets profil',
        COMPANY_DETAILS: 'Företagets uppgifter',
        EDIT_DETAILS: 'Redigera detaljerade uppgifter',
        WORKING_HOURS: 'Öppettider',
        VAT_NUMBER: 'VAT-nummer',
        REGISTRY_CODE: 'Organisationsnummer',
        CONTACT_PERSON: 'Kontaktperson',
        EDIT_PERSON: 'Redigera personen',
        PHONE_NO: 'Telefonnummer',
        EMAIL: 'E-post',
        ADDRESSES: 'Adresser',
        OFFICIAL_ADDRESS: 'Företagets officiella adress',
        EDIT_OFFICIAL_ADDRESS: 'Redigera företagets officiella adress',
        MAIN_ADDRESS: 'Företagets huvudadress',
        EDIT_MAIN_ADDRESS: 'Redigera företagets huvudadress',
        EDIT_INVOICE_ADDRESS: 'Redigera faktureringsadress',
        INVOICE_CONFIGURATION: 'Fakturakonfiguration',
        INVOICE_COMPANY_DETAILS: 'Företagets uppgifter för faktura',
        INVOICE_CONTACT_PERSON: 'Kontaktperson för faktura',
        INVOICE_RECEIVER_EMAIL: 'E-postadress för fakturamottagaren',
        INVOICE_TYPE: 'Fakturatyp',
        DUE_DATE_DAYS: 'Betalningsfrist i dagar',
        INVOICE_ADDRESS: 'Fakturaadress',
        ADDRESS: 'Adress',
        CITY: 'Ort',
        REGION: 'Region',
        POSTAL_CODE: 'Postnummer',
        COUNTRY: 'Land',
        EDIT: 'Redigera',
        DETAILED: 'Detaljerad',
        REGULAR: 'Vanlig',
        APPLY_THIS_INFORMATION_TO_MAIN_ADDRESS: 'Använd denna information för företagets huvudadress',
        APPLY_THIS_INFORMATION_TO_INVOICE_ADDRESS: 'Använd denna information för fakturaadress',
        APPLY_THIS_INFORMATION_TO_INVOICE_DETAILS: 'Använd denna information för företagets uppgifter',
        APPLY_THIS_INFORMATION_TO_INVOICE_CONTACT: 'Använd denna information för kontaktpersonen för fakturan',
        DAYS: 'dagar',
        EDIT_COMPANY_DETAILS: 'Redigera företagets uppgifter',
        EDIT_INVOICE_COMPANY_DETAILS: 'Redigera företagsuppgifter för fakturan',
        EDIT_CONTACT_PERSON: 'Redigera kontaktperson',
        EDIT_INVOICE_CONTACT_PERSON: 'Redigera kontaktpersonen för fakturan',
        SAVE_CHANGES: 'Spara ändringarna',
    },
    CLAIMS: "Reklamationer",
    CLAIM: {
        ADD_NEW_FROM_SHIPMENT: 'Lägg till ny reklamation från sändning:',
        ADD_NEW_FROM_SHIPMENT_DESCRIPTION: 'För att lägga till en reklamation måste du hitta rätt sändning.',
        SEARCH_FOR_A_SHIPMENT_TITLE: 'Sök efter sändning med nummer:',
        SEARCH_BY_KEYWORD: "Sök efter nyckelord",
        PLEASE_CONFIRM: "Lämna in",
        CONFIRM_TEXT: "Försäkra dig om att alla uppgifter stämmer.",
        DOWNLOAD_PDF: "Ladda ned PDF",
        DAMAGE_COST: "Reklamation",
        NUMBER: "Nummer",
        SAVE_AS_DRAFT_SUCCESS: 'Reklamationen har sparats som ett utkast',
        DELETE_DRAFT_SUCCESS: 'Reklamationsutkastet har raderats',
        DELETE_DRAFT_CONFIRMATION: 'Är du säker på att du vill radera reklamationsutkastet?',
        COMPANY_INFO_TITLE: 'Den reklamerande partens uppgifter',
        GENERAL_INFO: 'Allmän information',
        SHIPMENT_NR: 'Transportnummer',
        CLAIM_DATE: 'Datum för inlämnande',
        DIRECTION: {
            TITLE: 'Riktning',
            EXPORT: 'Utgående',
            IMPORT: 'Inkommande'
        },
        COMPANY: {
            "NAME": "Företagets namn",
            "ADDRESS": "Adress",
            "CONTACT": "Kontaktperson",
            "EMAIL": "E-post",
            "PHONE": "Telefon",
            "IBAN": "Bankkonto"
        },
        SIDEBAR: {
            DETAILS: "Uppgifter om reklamationen",
            STATUSES: "Reklamationens status",
        },
        DATE: "Datum för inlämnande",
        SHIPMENT_NUMBER: "Transportnummer",
        REASON: "Anledning till reklamationen",
        REASON_ERROR: "Minst en anledning ska väljas",
        REASONS: {
            "FULLY_LOST": "Fullständig förlust av transporten",
            "FULLY_DAMAGED": "Fullständig skada på transporten",
            "PARTLY_LOST": "Delvis förlust av transporten",
            "PARTLY_DAMAGED": "Delvis skada på transporten",
            "WAS_LATE": "Försening av transporten",
        },
        REASON_FULLY_TITLE: "Beskrivning på hela transporten",
        REASON_PARTLY_TITLE: "Beskrivning på delvis skadad eller förlorad transport",
        SEND_CLAIM: "Lämna in reklamationen",
        DESCRIPTION_TITLE: "Tidpunkten för upptäckt, beskrivning och skadebeloppet",
        DESCRIPTION: "Tidpunkten för upptäckt av skadan och beskrivning",
        COST: "Skadebelopp (€)",
        SHIPMENT_INFO: {
            DESCRIPTION: "Beskrivning på varor",
            QUANTITY: "Antalet förpackningsenheter",
            PACKAGE_TYPE: "Typ av paket",
            WEIGHT: "Bruttovikt (kg)",
            COST: "Transportens värde (€)"
        },
        FILES_ADDED: {
            attachedCmr: 'Undertecknad fraktsedel',
            attachedInvoices: 'Köp- eller säljfaktura (-fakturor) för transporten',
            attachedExpenseDocuments: 'Faktura (fakturor) för reparationskostnaderna',
            attachedLabels: 'Packsedel',
            attachedPictures: 'Foton på skadade varor och skadad förpackning',
            attachedOtherDocuments: 'Andra dokument som intygar skadans omfattning',
        },
        STATUS_Draft: 'Utkast',
        STATUS_Processing: 'Hantering pågår',
        STATUS_Accepted: 'Accepterad',
        STATUS_Rejected: 'Avslagen',
        STATUS_DECISION: 'Beslut',
        STATUS_DESCRIPTION: 'Beskrivning på beslutet'
    },
    One_office_required: 'Vänligen välj minst en plats!'
};
